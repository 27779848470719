<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        In House Training
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Title "
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCourseForm.title"
                />
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Sub Title"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="sub_title"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCourseForm.sub_title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Contact Title "
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="contact_title"
                rules="required"
              >
                <b-form-input
                  id="blog-contact_title-slug"
                  v-model="addCourseForm.contact_title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Email"
              label-for="email-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="email"
              >
                <b-form-input
                  id="email"
                  v-model="addCourseForm.email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="phone"
              label-for="email-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="addCourseForm.phone"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="summary"
              label-for="summary-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="summary"
              >
                <b-form-input
                  id="summary"
                  v-model="addCourseForm.summary"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h3>Sections</h3>
            <div class="border rounded p-2">
              <b-media
                v-for="(item, i) in addCourseForm.sections"
                :key="i"
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="
                      item.icon ? `https://api.lmitac.com/${item.icon}` : Tabimage
                    "
                    height="150"
                    width="200"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <b-card-text class="my-50" />
                  <b-col
                    v-if="i != 0"
                    cols="12"
                    class="mt-50"
                    style="text-align: end"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline"
                      class="mr-1"
                      @click="removeSection(i)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-col>
                  <div class="d-inline-block">
                    <b-form-group
                      label="header image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                      >
                        <input
                          ref="newThumbnailgoal"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          class="form-control"
                          @change="uploadImageOurGoal($event, item)"
                        >
                        <b-form-input
                          id="alt-image"
                          v-model="item.icon"
                        />
                        <!-- <b-form-file
                          ref="refInputE2"
                          v-model="form.our_goal_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab" /> -->
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Ttle"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="item.title"
                      />
                    </b-form-group>
                    <b-form-group
                      label="description"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="item.description"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>

                <hr>
              </b-media>

              <b-row
                class="text-end"
                style="text-align: end"
              >
                <b-col
                  cols="12"
                  class="mt-50"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="newSection"
                  >
                    new
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h3>Feedbacks</h3>
            <div class="border rounded p-2">
              <b-media
                v-for="(item, i) in addCourseForm.feedbacks"
                :key="i"
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-body>
                  <b-card-text class="my-50" />
                  <b-col
                    v-if="i != 0"
                    cols="12"
                    class="mt-50"
                    style="text-align: end"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline"
                      class="mr-1"
                      @click="removeFeedback(i)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-col>

                  <b-form-group
                    label="Name"
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="name "
                      rules="required"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="item.name"
                      />
                      <!-- <b-form-file
                          ref="refInputE2"
                          v-model="form.our_goal_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab" /> -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="company"
                    label-for="blog-edit-company"
                    class="mb-2"
                  >  <validation-provider
                      #default="{ errors }"
                      name="company "
                      rules="required"
                    >
                    <b-form-input
                      id="alt-company"
                      v-model="item.company"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="feedback"
                    label-for="blog-edit-feedback"
                    class="mb-2"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="feedback "
                      rules="required"
                    >
                    <b-form-input
                      id="alt-feedback"
                      v-model="item.feedback"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-media-body>

                <hr>
              </b-media>

              <b-row
                class="text-end"
                style="text-align: end"
              >
                <b-col
                  cols="12"
                  class="mt-50"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="newFeedback"
                  >
                    new Feedback
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Description"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
               <QuillEditor
                  :editorRef="'descriptionEditor'"
                  v-model="addCourseForm.description"
                />
               
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Sub Description "
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
               <QuillEditor
                  :editorRef="'sub_description4Editor'"
                 v-model="addCourseForm.sub_description"
                />
               
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Contact Description "
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
               <QuillEditor
                  :editorRef="'contact_descriptionEditor'"
               v-model="addCourseForm.contact_description"
                />
              
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from 'bootstrap-vue'
import Editor from '@tinymce/tinymce-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
import QuillEditor from '@core/components/editor/Editor'
export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const categoriesList = ref([])
    const addCourseForm = reactive({
      title: '',
      sub_title: '',
      contact_title: '',
      description: '',
      sub_description: '',
      contact_description: '',
      email: '',
      phone: '',
      summary: '',
      feedbacks: [
        {
          name: '',
          company: '',
          feedback: '',
        },
      ],
      sections: [
        {
          icon: '',
          title: '',
          description: '',
        },
      ],
    })
    store.dispatch('categories/AllCategory').then(response => {
      categoriesList.value = response.data.data
    })
    const getContent =() => {
   
        store.dispatch('content/GetDynamicContenet').then(response => {
            console.log(response)
          // addCourseForm.related_courses=JSON.parse(response?.data.related_courses)
          addCourseForm.title = response?.page.title
          addCourseForm.sub_title = response?.page.sub_title
          addCourseForm.contact_title = response?.page.contact_title
          addCourseForm.description = response?.page.description
          addCourseForm.sub_description = response?.page.sub_description
          addCourseForm.contact_description = response?.page.contact_description
          addCourseForm.email = response?.page.email
          addCourseForm.phone = response?.page.phone
          addCourseForm.summary = response?.summary
          addCourseForm.sections = response?.sections
        })
    
    }
    getContent()

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
       
          store
            .dispatch('content/updateDynamicContenet', addCourseForm)

            .then(response => {
              // console.log(response)
              // refPreviewEl.value.src = 'media/svg/files/blank-image.svg'
              // refPreviewE2.value.src = 'media/svg/files/blank-image.svg'

              Vue.swal({
                title: 'Dynamic Content Added ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    const newSection = () => {
      addCourseForm.sections.push({
        title: '',
        icon: '',
        description: '',
      })
    }
    const removeSection = i => {
      addCourseForm.sections.splice(i, 1)
    }
    const newFeedback = () => {
      addCourseForm.feedbacks.push({
        name: '',
        company: '',
        feedback: '',
      })
    }
    const removeFeedback = i => {
      addCourseForm.feedbacks.splice(i, 1)
    }
    function example_image_upload_handler(
      blobInfo,
      success,
      failure,
      progress,
    ) {
      let xhr
      let formData
      const token = localStorage.getItem('token')
      console.log('token', token)
      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', 'https://api.lmitac.com/api/upload_image_tiny_mce')

      xhr.upload.onprogress = function (e) {
        progress((e.loaded / e.total) * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        let json

        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }

        success(json.location)
      }

      xhr.onerror = function () {
        failure(
          `Image upload failed due to a XHR Transport error. Code: ${xhr.status}`,
        )
      }

      formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    }
    const uploadImageOurGoal = (i, item) => {
      console.log(i)
      const file = i.target.files[0]

      const fd = new FormData()

      fd.append('image', file)
      fd.append('path', 'about')
      store.dispatch('setting/uploadFile', fd).then(response => {
        console.log('res', response)
        item.icon = response.location
      })
    }
    return {
      newSection,
      newFeedback,
      removeFeedback,
      removeSection,
      uploadImageOurGoal,
      example_image_upload_handler,
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      categoriesList,
      getContent,

      save,
      required,
      email,
    }
  },

  components: {
    Editor,
    QuillEditor,
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    Editor,
    BLink,

    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}
</style>
